import { djangoErrorsToString } from '@/assets/constants'

const getRenamesMapFromHeaders = headers => {
  return headers.map(h => [h.value, h.text]).reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
}

const renameObjectKeys = (obj, renamesMap) => {
  return Object.entries(obj)
    .map(([k, v]) => [renamesMap[k] || k, v])
    .reduce((a, [k, v]) => ((a[k] = v), a), {})
}

export default {
  install(Vue) {
    Vue.prototype.$handleValidationError = function ({ error, headers = [] }) {
      headers = headers || []
      let msg = error
      if (msg?.response?.data) {
        const renamesMap = getRenamesMapFromHeaders(headers)
        const renamed = renameObjectKeys(error?.response?.data || error, renamesMap)
        msg = djangoErrorsToString(renamed)
      }
      return this.$swal({
        title: 'Error',
        icon: 'error',
        text: msg,
        toast: true,
        grow: 'row',
        width: '30em',
        timer: 5000,
        timerProgressBar: true,
        position: 'top-end',
      })
    }
  },
}
