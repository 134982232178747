import { format, parse } from 'date-fns'

export const statuses = {
  MEETING: 'Reunion',
  RENEW: 'Renovaciones',
  SUCCESS: 'Firmado',
  FIDELIZACION: 'Fidelizacion',
  RECALL: 'Volver a llamar',
  RESCHEDULE_MEETING: 'Aplazada con fecha',
  ACCEPT: 'Proceso aceptacion',
  PTE_ENVIO_EMAIL: 'PTE envio email',
  GESTIONES_DE_AGENTES: 'Gestiones de agentes',
  PENDIENTE_FACTURA: 'Pendiente factura',
  FACTURA_RECIBIDA: 'Factura recibida',
  ESTUDIO_REALIZADO: 'Estudio realizado',
  ESTUDIO_ENVIADO: 'Estudio enviado',
  FUTURAS_OFERTAS: 'Futuras ofertas',
  INVALID_NUMBER: 'Número inválido',
  TEST: 'Testear',
  BAD_CONTACT: 'Mal contacto',
  OLDMAN: 'Persona major',
  NOT_LOCATED: 'No localizado',
  OPERATOR_FAIL: 'No le interesa',
  AGENT_FAIL: 'No firmado',
  PROCESSING: 'En proceso',
  OPEN: 'Pendiente',
  REGISTROS: 'Registros',
  WORK: 'Trabajo',
  WORK_DONE: 'Trabajo completo',
  WORK_NOT_DONE: 'Trabajo incompleto',
  GESTION: 'Gestionadas TV',
  DOES_NOT_COOPERATE: 'No colabora',
}

const DT_FORMAT = 'yyyy-MM-ddTHH:mm:ss'
const DT_FORMAT_WOUT_SECONDS = 'dd/MM/yyyy HH:mm'
const DATE_FORMAT = 'yyyy-MM-dd'
export const DATETIME_FORMAT = DATE_FORMAT + ' HH:mm'

export const CV_DT_FORMAT = 'dd/MM/yyyy HH:mm'
export const CV_DATE_FORMAT = 'dd/MM/yyyy'

const toCalendar = someDate => format(someDate, DATE_FORMAT)
const fromGGformatToDate = someString => parse(someString, DT_FORMAT_WOUT_SECONDS, new Date())

export const getPositionColor = position => {
  const posMap = {
    manager: 'orange',
    operator: 'blue',
    admin: 'red',
    solar_installer: 'green',
  }
  return posMap[position]
}

/** @type {Map} */
const STATUS_COLORS_MAP = new Map([
  [statuses.MEETING, 'green'],
  [statuses.RENEW, 'companyGreen'],
  [statuses.SUCCESS, 'green'],
  [statuses.FIDELIZACION, 'green'],
  [statuses.RECALL, 'orange'],
  [statuses.RESCHEDULE_MEETING, 'orange'],
  [statuses.ACCEPT, 'blue'],
  [statuses.PTE_ENVIO_EMAIL, 'blue'],
  [statuses.GESTIONES_DE_AGENTES, 'blue'],
  [statuses.PENDIENTE_FACTURA, 'blue'],
  [statuses.FACTURA_RECIBIDA, 'blue'],
  [statuses.ESTUDIO_REALIZADO, 'blue'],
  [statuses.ESTUDIO_ENVIADO, 'blue'],
  [statuses.INVALID_NUMBER, 'red'],
  [statuses.TEST, 'red'],
  [statuses.BAD_CONTACT, 'red'],
  [statuses.OLDMAN, 'red'],
  [statuses.NOT_LOCATED, 'red'],
  [statuses.OPERATOR_FAIL, 'red'],
  [statuses.AGENT_FAIL, 'red'],
  [statuses.PROCESSING, 'grey'],
  [statuses.OPEN, 'grey'],
  [statuses.FUTURAS_OFERTAS, 'blue'],
  [statuses.REGISTROS, 'grey'],
  [statuses.DOES_NOT_COOPERATE, 'red'],
  ['Fidelizable', 'green'],
  ['No interesa', 'red'],
  ['No lo tiene', 'red'],
  ['Gestionadas TV', 'blue'],
])

export const ourColor = '#004680'

export const cardStatusColors = status => {
  return STATUS_COLORS_MAP.get(status) || 'grey'
}

export const statusColors = status => {
  return cardStatusColors(status) + '--text'
}

const managerStatusColors = status => {
  const colorsMap = {
    Firmado: 'green',
    'No firmado': 'red',
    'No localizado': 'purple',
    'Aplazada con fecha': 'orange',
  }
  return colorsMap[status]
}

export const T20TD = '2.0TD'
export const T30TD = '3.0TD'
export const T61TD = '6.1TD'
export const T62TD = '6.2TD'
export const T63TD = '6.3TD'
export const T64TD = '6.4TD'

export const RL1 = 'RL.1'
export const RL2 = 'RL.2'
export const RL3 = 'RL.3'
export const RL4 = 'RL.4'
export const RLTA5 = 'RLTA5'
export const RLTA6 = 'RLTA6'
export const RLTB5 = 'RLTB5'
export const RLTB6 = 'RLTB6'

export const TARIFAS = [T20TD, T30TD, T61TD, T62TD, T63TD, T64TD]
export const TARIFAS_GAS = [RL1, RL2, RL3, RL4, RLTA5, RLTA6, RLTB5, RLTB6]

export const djangoErrorsToString = error => {
  const trans = {
    company_from: 'Comercializadora actual',
    document_type: 'Tipo de documento',
    cnae: 'Actividad (cnae)',
    schedule: 'Hora',
    non_field_errors: 'Error',
  }
  if (error instanceof Array) {
    return error.map(e => djangoErrorsToString(e)).join('\n')
  } else if (error instanceof Object || typeof error === 'object') {
    return Object.entries(error)
      .map(([key, value]) => {
        if (!value) return
        key = trans[key] || key
        return `${key}: ${djangoErrorsToString(value)}`
      })
      .join('\n')
  } else if (error instanceof String || typeof error === 'string') {
    return error
  } else {
    console.error(error)
    throw Error('Internal exception while handling backend error.')
  }
}
const cleanEmpty = obj =>
  Object.entries(obj)
    .map(([k, v]) => [k, v && typeof v === 'object' ? cleanEmpty(v) : v])
    .reduce((a, [k, v]) => (v == null ? a : ((a[k] = v), a)), {})

export const puntoSuministroStatuses = {
  SUCCESS: {
    text: 'Firmado',
    value: 0,
    color: 'green',
  },
  FIDELIZABLE: {
    text: 'Fidelizable',
    value: 1,
    color: 'green',
    onlyForClient: true,
  },
  ACCEPT: { value: 2, text: 'Proceso aceptacion', color: 'blue' },
  PENDIENTE_FACTURA: { value: 3, text: 'Pendiente factura', color: 'blue' },
  ESTUDIO_REALIZADO: { value: 4, text: 'Estudio realizado', color: 'blue', filesRequired: true },
  ESTUDIO_ENVIADO: { value: 5, text: 'Estudio enviado', color: 'blue' },
  FACTURA_RECIBIDA: { value: 6, text: 'Factura recibida', color: 'blue' },
  FUTURAS_OFERTAS: { value: 8, text: 'Futuras ofertas', color: 'blue' },
  FAIL: { value: 7, text: 'No interesa', color: 'red' },
  ERROR: { value: 9, text: 'No lo tiene', color: 'red' },
  NO_FIRMADO: { value: 10, text: 'No firmado', color: 'red' },
  NULL: { value: null, text: 'Sin estado', color: 'grey' },
}
export const defaultPaths = {
  admin: '/admin/base_de_datos',
  operator: '/operator',
  manager: '/manager',
  solar_installer: '/solar_installer/agenda',
  salemanager_admin: '/manager',
  salemanager: '/televenta_agente',
}
export const findStatus = ({ byText, byValue }) =>
  Object.values(puntoSuministroStatuses).find(({ text, value }) => (byText ? byText === text : byValue === value))

export function getWebsocketUrl(token, aep = 'ws') {
  return location.origin.replace('http', 'ws').replace('8083', '8000') + `/${aep}/?token=` + token
}
export const onlyUnique = (value, index, s) => s.indexOf(value) === index
export const showInput = (letter, number, tarif) => {
  if (!letter || !number || !tarif) return false
  letter = letter.toLowerCase()
  number = parseInt(number)
  tarif = tarif.toUpperCase()
  if (tarif === '2.0TD') return letter === 'p' ? [1, 2].includes(number) : [1, 2, 3].includes(number)
  return true
}

export const userIcons = {
  DUBRASKA: 'mdi-face-woman-shimmer',
  ABIGAIL: 'mdi-face-woman',
  'DUBRASKA ROSENDE': 'mdi-face-woman-shimmer',
  'ABIGAIL CABANILLA': 'mdi-face-woman',
}

export const fileTypes = {
  1: 'DNI',
  2: 'CIF',
  3: 'Factura',
  4: 'Contrato',
  5: 'DNI Representante',
  6: 'Estudio',
  7: 'Otro',
  8: 'Carta de precios',
  9: 'Correo',
}

export const solarFileTypes = [
  { value: 1, text: 'Estudio' },
  { value: 2, text: 'Presupuesto' },
  { value: 3, text: 'Factura' },
  { value: 4, text: 'Fotografia' },
  { value: 5, text: 'Presupuesto competencia' },
  { value: 6, text: 'Otros' },
]

export const clientTypes = [
  {
    text: 'Hogar',
    value: 'F',
  },
  {
    text: 'Negocio / Autónomo',
    value: 'J',
  },
  { text: '-', value: null },
]

export function isImage(url) {
  return url.match(/\.(jpeg|jpg|gif|png|webp|svg)$/) != null
}

export const getIcon = fileUrl => {
  fileUrl = String(fileUrl).toLowerCase()
  if (fileUrl.endsWith('jpg') || fileUrl.endsWith('jpeg')) {
    return 'mdi-file-jpg-box'
  } else if (fileUrl.endsWith('png')) {
    return 'mdi-file-png-box'
  } else if (fileUrl.endsWith('pdf')) {
    return 'mdi-file-pdf-box'
  }
  return 'mdi-file'
}

export async function desktopNoty(title, body, onclick) {
  let registration = await navigator.serviceWorker.getRegistration()
  const icon = 'https://crm.gestiongroup.es/pwa-192x192.png'
  try {
    await Notification.requestPermission()
  } catch (e) {
    console.error(e)
  }
  registration.showNotification(title, { body, icon, onclick })
}

export const documentTypes = [
  {
    text: 'CIF',
    value: 0,
  },
  {
    text: 'NIF',
    value: 1,
  },
  {
    text: 'Pasaporte',
    value: 2,
  },
  {
    text: 'Permiso Residencia',
    value: 3,
  },
  {
    text: 'CIF Internacional',
    value: 4,
  },
  { text: '-', value: null },
]

export const contractStatus = {
  PTE_TRAMITACION: 1,
  EN_TRAMITACION: 7,
  KO: 2,
  SOLICITUD_ENVIADA: 3,
  PTE_COMERCIALIZADORA: 4,
  ACTIVO: 5,
  INACTIVO: 8,
  KO_DEFINITIVO: 9,
  SALDADO: 15,
  KO_SCORING: 16,
  TRAMITACION_A_FUTURO: 17,
}

export const sipsStatuses = [
  { text: '-', value: null },
  { text: 'No contactado', value: 2, color: 'warning' },
  { text: 'Empresa cerrada', value: 4, color: 'red' },
  { text: 'No interesa', value: 5, color: 'red' },
  { value: 6, text: 'Proceso aceptacion', color: 'blue' },
  { value: 7, text: 'Volver a pasar', color: 'orange' },
]

export const contractStatuses = [
  {
    text: 'EN TRAMITACION',
    value: 7,
    color: 'info',
  },
  {
    text: 'PTE TRAMITACION',
    value: 1,
    color: 'info',
  },
  {
    text: 'TRAMITACION A FUTURO',
    value: 17,
    color: 'info',
  },
  {
    text: 'PTE COMERC',
    value: 4,
    color: 'info',
  },
  {
    text: 'SOLICITUD ENVIADA',
    value: 3,
    color: 'info',
  },
  {
    text: 'ACTIVO',
    value: 5,
    color: 'success',
  },
  {
    text: 'INACTIVO',
    value: 8,
    color: 'error',
  },
  {
    text: 'KO',
    value: 2,
    color: 'error',
  },
  {
    text: 'KO DEFINITIVO',
    value: 9,
    color: 'error',
  },
  // {
  //   text: 'KO (actualizado)',
  //   value: 6,
  // },
  {
    text: 'SALDADO',
    value: 15,
    color: 'error',
  },
  {
    text: 'KO SCORING',
    value: 16,
    color: 'error',
  },
]

export const hogarContractStatuses = [
  {
    text: 'EN TRAMITACION',
    value: 7,
    color: 'info',
  },
  {
    text: 'PTE TRAMITACION',
    value: 1,
    color: 'info',
  },
  {
    text: 'TRAMITACION_A_FUTURO',
    value: 17,
    color: 'info',
  },
  {
    text: 'PTE COMERC',
    value: 4,
    color: 'info',
  },
  {
    text: 'SCORING RECHAZADO',
    value: 10,
    color: 'info',
  },
  {
    text: 'POR FIRMAR',
    value: 11,
    color: 'info',
  },
  {
    text: 'PTE VERIFICACION',
    value: 12,
    color: 'info',
  },
  {
    text: 'EN ACTIVACION ',
    value: 13,
    color: 'info',
  },
  {
    text: 'ACTIVO',
    value: 5,
    color: 'success',
  },
  {
    text: 'INACTIVO',
    value: 8,
    color: 'error',
  },
  {
    text: 'KO',
    value: 2,
    color: 'error',
  },
  {
    text: 'KO DEFINITIVO',
    value: 9,
    color: 'error',
  },
  {
    text: 'SALDADO',
    value: 15,
    color: 'error',
  },
]

export const contractTypes = [
  {
    text: 'CC',
    value: 1,
  },
  {
    text: 'FIDE',
    value: 2,
  },
  {
    text: 'NNSS',
    value: 3,
  },
]

export const solarHeaders = [
  {
    text: 'ID',
    value: 'id',
  },
  {
    value: 'is_starred',
    onlyForAdmin: true,
  },
  {
    text: 'Cliente',
    value: 'name',
  },
  {
    text: 'Persona de contacto',
    value: 'persona_de_contacto',
  },
  {
    text: 'Captador',
    value: 'created_by_fullname',
    onlyForAdmin: true,
  },
  {
    text: 'Población',
    value: 'locality',
  },
  {
    text: 'Fecha recepción',
    value: 'created_at',
  },
  {
    text: 'Fecha actualizacion',
    value: 'last_modified',
  },
  {
    text: 'Estado',
    value: 'status',
    width: 300,
  },
  {
    text: 'Fecha VTO',
    value: 'action_dt',
  },
]

export const serviceStatusItems = ['PTE COMERC', 'ACTIVO', 'KO DEFINITIVO', 'INACTIVO']

export const STATES = Object.freeze([
  {
    value: 143,
    text: 'ÁLAVA',
  },
  {
    value: 141,
    text: 'ALBACETE',
  },
  {
    value: 111,
    text: 'ALICANTE',
  },
  {
    value: 127,
    text: 'ALMERÍA',
  },
  {
    value: 120,
    text: 'ASTURIAS',
  },
  {
    value: 153,
    text: 'ÁVILA',
  },
  {
    value: 130,
    text: 'BADAJOZ',
  },
  {
    value: 115,
    text: 'BALEARES',
  },
  {
    value: 108,
    text: 'BARCELONA',
  },
  {
    value: 142,
    text: 'BURGOS',
  },
  {
    value: 140,
    text: 'CÁCERES',
  },
  {
    value: 114,
    text: 'CÁDIZ',
  },
  {
    value: 134,
    text: 'CANTABRIA',
  },
  {
    value: 133,
    text: 'CASTELLÓN',
  },
  {
    value: 137,
    text: 'CIUDAD REAL',
  },
  {
    value: 126,
    text: 'CÓRDOBA',
  },
  {
    value: 150,
    text: 'CUENCA',
  },
  {
    value: 125,
    text: 'GIRONA',
  },
  {
    value: 123,
    text: 'GRANADA',
  },
  {
    value: 148,
    text: 'GUADALAJARA',
  },
  {
    value: 128,
    text: 'GUIPÚZCOA',
  },
  {
    value: 135,
    text: 'HUELVA',
  },
  {
    value: 149,
    text: 'HUESCA',
  },
  {
    value: 132,
    text: 'JAÉN',
  },
  {
    value: 118,
    text: 'LA CORUÑA',
  },
  {
    value: 146,
    text: 'LA RIOJA',
  },
  {
    value: 117,
    text: 'LAS PALMAS',
  },
  {
    value: 138,
    text: 'LEÓN',
  },
  {
    value: 139,
    text: 'LÉRIDA',
  },
  {
    value: 145,
    text: 'LUGO',
  },
  {
    value: 107,
    text: 'MADRID',
  },
  {
    value: 112,
    text: 'MÁLAGA',
  },
  {
    value: 113,
    text: 'MURCIA',
  },
  {
    value: 131,
    text: 'NAVARRA',
  },
  {
    value: 159,
    text: 'NO HAY PROVINCIA',
  },
  {
    value: 147,
    text: 'ORENSE',
  },
  {
    value: 152,
    text: 'PALENCIA',
  },
  {
    value: 122,
    text: 'PONTEVEDRA',
  },
  {
    value: 144,
    text: 'SALAMANCA',
  },
  {
    value: 119,
    text: 'SANTA CRUZ DE TENERIFE',
  },
  {
    value: 154,
    text: 'SEGOVIA',
  },
  {
    value: 110,
    text: 'SEVILLA',
  },
  {
    value: 156,
    text: 'SORIA',
  },
  {
    value: 124,
    text: 'TARRAGONA',
  },
  {
    value: 155,
    text: 'TERUEL',
  },
  {
    value: 129,
    text: 'TOLEDO',
  },
  {
    value: 109,
    text: 'VALENCIA',
  },
  {
    value: 136,
    text: 'VALLADOLID',
  },
  {
    value: 116,
    text: 'VIZCAYA',
  },
  {
    value: 151,
    text: 'ZAMORA',
  },
  {
    value: 121,
    text: 'ZARAGOZA',
  },
])

export const positionsIcons = {
  admin: 'mdi-shield-account',
  operador: 'mdi-face-agent',
  manager: 'mdi-handshake',
}

export const filesFields = [
  {
    text: 'Factura',
    value: 3,
  },
  {
    text: 'Contrato',
    value: 4,
  },
  {
    text: 'Estudio',
    value: 6,
  },
  {
    text: 'Carta de precios',
    value: 8,
  },
]

export const cardFileTypes = [
  {
    text: 'DNI',
    value: 1,
  },
  {
    text: 'CIF',
    value: 2,
  },
  {
    text: 'DNI Repr. Legal',
    value: 5,
  },
]

export const solarPanelStatuses = [
  { text: 'PENDIENTE', value: 1, color: 'black' },
  // { text: 'PENDIENTE ESTUDIO', value: 4, color: '#D35400' },
  // { text: 'ESTUDIO REALIZADO', value: 5, color: 'blue' },
  // { text: 'ESTUDIO VALORADO', value: 11, admin: true, color: 'blue' },

  { text: 'PTE PRESUPUESTO', value: 21, color: 'blue' },
  { text: 'PRESUPUESTO REALIZADO', value: 19, color: '#8E44AD' },
  { text: 'PRESUPUESTO ENVIADO', value: 12, admin: true, color: '#8E44AD' },
  { text: 'PRESUPUESTO ACEPTADO', value: 13, admin: true, color: 'green' },
  { text: 'PRESUPUESTO NO ACEPTADO', value: 20, color: 'red' },

  { text: 'PTE REVALORACION', value: 10, admin: true, color: 'blue' },

  { text: 'PTE FINANCIACIÓN', value: 15, color: 'blue' },
  // { text: 'FINANCIACIÓN ACEPTADA', value: 16, color: 'green' },
  // { text: 'FINANCIACIÓN DENEGADA', value: 17, color: 'red' },

  { text: 'PTE INSTALACION', value: 6, color: 'blue' },
  // { text: 'INSTALACION PROGRAMADA', value: 7, color: 'blue' },
  // { text: 'PTE MATERIAL', value: 8, color: 'green' },
  { text: 'INSTALACION FINALIZADA', value: 9, color: 'green' },

  { text: 'VOLVER A LLAMAR', value: 2, color: 'red' },

  // { text: 'VISITA PROGRAMADA', value: 3, color: '#34495E' },

  // { text: 'FACTURA ENVIADA', value: 14, admin: true, color: 'green' },

  { text: 'ANULADO', value: 18, color: 'red' },
]

export const SOLAR_MATERIAL_TYPES = [
  { value: 1, text: 'Paneles fotovoltaicos' },
  { value: 2, text: 'Estructura' },
  { value: 3, text: 'Inversor y comunicaciones' },
  { value: 4, text: 'Protecciones' },
  { value: 5, text: 'Instalación' },
  { value: 6, text: 'Baterías' },
]

export const SOLAR_INSTALLATION_CHOICES = [
  {
    text: 'VIV. unifamiliar',
    value: 1,
  },
  {
    text: 'CCPP',
    value: 2,
  },
  {
    text: 'Parking',
    value: 3,
  },
  {
    text: 'Solar',
    value: 4,
  },
  {
    text: 'Comercial',
    value: 6,
  },
  {
    text: 'Industrial',
    value: 5,
  },
]

export const accountingHeadersMap = {
  trimester: 'Trimestre',
  date: 'Fecha',
  factura: 'Nº Factura',
  client: 'Cliente',
  dni: 'DNI/NIF',
  autonomo: 'Autonomo',
  autonomo_persistent: 'Autonomo',
  base_imponible: 'Base imponible',
  iva_cuote: 'Cuota IVA',
  iva_type: 'Tipo de IVA',
  retention_15: 'RETENCIÓN 15%',
  retention_7: 'RETENCIÓN 7%',
  iva_21: 'IVA 21%',
  total_factura: 'Total factura',
}

export const getAccountingHeaders = accountingType => {
  let h = ['ERROR!!!']
  if (accountingType === 1) {
    h = ['client', 'dni', 'iva_type', 'base_imponible', 'retention_15', 'retention_7', 'iva_cuote']
  } else if (accountingType === 2) {
    h = ['autonomo', 'autonomo_persistent', 'dni', 'base_imponible', 'retention_15', 'retention_7', 'iva_21']
  } else if (accountingType === 3) {
    h = ['client', 'dni', 'iva_type', 'base_imponible', 'iva_cuote']
  }
  return [...['trimester', 'date', 'factura'], ...h, 'total_factura', 'actions']
}

export const SOLAR_SUMINISTRO_CHOICES = [
  {
    text: 'Monofásico',
    value: 1,
  },
  {
    text: 'Trifásico',
    value: 2,
  },
]

export const userTypes = [
  { text: 'Administrador', value: 'admin' },
  { text: 'Operador', value: 'operator' },
  { text: 'Agente', value: 'manager' },
  { text: 'Instalador', value: 'solar_installer' },
  { text: 'Resp comerc', value: 'salemanager_admin' },
  { text: 'Agente televenta', value: 'salemanager' },
]

export const commissionTypes = [
  { text: 'Agente', value: 1 },
  { text: 'Canal', value: 2 },
  { text: 'Canal +5%', value: 5 },
  { text: 'Canal +10%', value: 6 },
  { text: 'Especial', value: 3 },
  { text: 'Operador', value: 4 },
]

export const badContact = 'Mal contacto'

export const origins = [
  { value: 'null', text: '(desconocido)' },
  { value: 1, text: 'Websales' },
  { value: 2, text: 'Nemon' },
  { value: 3, text: 'Infoempresa' },
  { value: 4, text: 'Cosmo' },
  { value: 5, text: 'Manual' },
  { value: 6, text: 'SIPS' },
  { value: 7, text: 'Z99M' },
]

export function debounce(func, wait, immediate) {
  let timeout
  return function () {
    const context = this
    const args = arguments
    const later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export const offersTypes = [
  {
    text: 'Fijo',
    value: 1,
  },
  {
    text: 'Index',
    value: 2,
  },
  {
    text: 'Indiferente',
    value: 3,
  },
]

export default Object.freeze({
  cleanEmpty,
  djangoErrorsToString,
  toCalendar,
  fromGGformatToDate,
  TARIFAS,
  TARIFAS_GAS,
  DT_FORMAT,
  CV_DT_FORMAT,
  DATE_FORMAT,
  DATETIME_FORMAT,
  CV_DATE_FORMAT,
  DT_FORMAT_WOUT_SECONDS,
  badContact,
  statuses,
  statusColors,
  managerStatusColors,
  cardStatusColors,
  onlyUnique,
  showInput,
})
