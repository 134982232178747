<template>
  <v-app>
    <websocket-progress-bar v-if="isAdmin" />
    <notification-dialog />
    <chat-widget />

    <v-app-bar v-if="isAuthorised && isOperator" style="flex: none; z-index: 10">
      <v-app-bar-nav-icon>
        <v-btn icon fab style="background-color: white">
          <v-img max-height="50px" max-width="50px" src="/new_logo.svg" />
        </v-btn>
      </v-app-bar-nav-icon>

      <v-spacer />

      <v-col cols="auto">
        <my-goals />
      </v-col>

      <v-col class="shrink">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <div v-on="on">
              <profile-dialog :user-id="userId" closeable like-avatar />
            </div>
          </template>
          <span>Perfil</span>
        </v-tooltip>
      </v-col>

      <v-col v-if="isOperator" class="shrink">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <div v-on="on">
              <notifications :filter="noty => noty.is_noty" color="success" />
            </div>
          </template>
          <span>Noticias</span>
        </v-tooltip>
      </v-col>

      <v-col v-if="isOperator" class="shrink">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <div v-on="on">
              <notifications :filter="noty => !!noty.id && !noty.solar && !noty.ticket_id" />
            </div>
          </template>
          <span>Notificaciones</span>
        </v-tooltip>
      </v-col>

      <v-col class="shrink">
        <v-tooltip v-if="isOperator" bottom>
          <template #activator="{ on }">
            <div v-on="on">
              <notifications title="Contratos KO" :filter="noty => !!noty.contract_id" color="error" />
            </div>
          </template>
          <span>Contratos KO</span>
        </v-tooltip>
      </v-col>

      <v-col class="shrink">
        <v-tooltip v-if="isOperator" bottom>
          <template #activator="{ on }">
            <div v-on="on">
              <notifications title="Futuras ofertas" :filter="noty => noty.future_offer" color="warning" />
            </div>
          </template>
          <span>Futuras ofertas</span>
        </v-tooltip>
      </v-col>

      <v-col v-if="isOperator" class="flex-grow-0">
        <change-account />
      </v-col>

      <v-tooltip bottom>
        <template #activator="{ on }">
          <div v-on="on">
            <service-info-popup />
          </div>
        </template>
        <span>Información</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template #activator="{ on }">
          <div v-on="on">
            <switch-theme />
          </div>
        </template>
        <span>Cambio de color</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template #activator="{ on }">
          <div v-on="on">
            <logout-icon />
          </div>
        </template>
        <span>Logout</span>
      </v-tooltip>
    </v-app-bar>
    <router-view v-if="allowed" />

    <v-main v-else>
      <v-container>
        <v-alert prominent type="warning">
          <v-row align="center">
            <v-col class="grow">
              <span>Lo siento...</span>
              <br />
              <span>El horario laboral es de 9:00 a 18:00 (GMT+1) de lunes a viernes. Volver a la hora correcta. </span>
            </v-col>
            <v-col class="shrink">
              <logout-icon block />
            </v-col>
          </v-row>
        </v-alert>
      </v-container>
    </v-main>

    <collapsed-cards v-if="allowed && $store.state.other.collapsedCards.length" />
  </v-app>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'App',
  components: {
    MyGoals: () => import('@/views/common/components/MyGoals.vue'),
    WebsocketProgressBar: () => import('@/components/misc/WebsocketProgressBar'),
    ChatWidget: () => import('@/views/chat/ChatWidget.vue'),
    NotificationDialog: () => import('@/views/common/dialogs/NotificationDialog.vue'),
    CollapsedCards: () => import('@/views/common/components/CollapsedCards'),
    LogoutIcon: () => import('@/views/common/components/LogoutIcon'),
    ServiceInfoPopup: () => import('@/views/common/ServiceInfoPopup'),
    ProfileDialog: () => import('@/views/common/profile/ProfileDialog'),
    SwitchTheme: () => import('@/views/common/components/SwitchTheme'),
    Notifications: () => import('@/views/common/components/Notifications'),
    ChangeAccount: () => import('@/views/common/ChangeAccount'),
  },
  computed: {
    ...mapGetters(['isOperator', 'isAuthorised', 'isAdmin']),
    ...mapState({
      isDark: state => state.other.isDark,
      userId: state => state.auth.userId,
      allowed: state => state.auth.allowed,
    }),
  },
  created() {
    this.$vuetify.theme.isDark = this.isDark === true
  },
}
</script>
