import newMessageSound from '@/assets/chat_new_message.mp3'
import { onlyUnique, desktopNoty } from '@/assets/constants'
import Vue from 'vue'

function playSound(sound) {
  const audio = new Audio(sound)
  audio.play()
}

const state = {
  socket: {
    isConnected: false,
    message: '',
    reconnectError: false,
  },
  predictiveCard: null,
  predictiveLoading: false,
  notys: [],
  notyDialogId: null,
  unreadMessages: [],
  usersOnline: [],
  desktopNoty: null,
}
const mutations = {
  setNotyDialog(state, noty_id) {
    state.notyDialogId = noty_id
  },
  SOCKET_ONOPEN(state, event) {
    Vue.prototype.$socket = event.currentTarget
    Vue.prototype.$socket.sendObj({ type: 'get_notys' })
    state.socket.isConnected = true
  },
  SOCKET_ONCLOSE(state) {
    state.socket.isConnected = false
  },
  SOCKET_ONERROR(_state, event) {
    console.error(event)
  },
  async SOCKET_ONMESSAGE(state, message) {
    const { type, data } = message
    if (type === 'get_predictive') {
      state.predictiveLoading = false
      state.predictiveCard = data
    } else if (type === 'get_notys') {
      state.notys = data
    } else if (type === 'new_version') {
      const dt = new Date().toLocaleTimeString()
      const msg = data || `${dt}: Una nueva versión está disponible. Actualice la página.`
      this._vm.$toasted.clear()
      this._vm.$toasted.info(msg)
    } else if (type === 'noty') {
      playSound(newMessageSound)
      const title = data.title || 'Notificación'
      const text = data.text || data
      if (document.visibilityState === 'visible') {
        let method = this._vm.$toasted.info
        if (data.type) method = this._vm.$toasted[data.type]
        method(text, {
          // duration: 3000,
          action: {
            text: 'OK',
            onClick: (e, toastObject) => {
              toastObject.goAway(0)
            },
          },
        })
      } else {
        this.desktopNoty?.close?.()
        this.desktopNoty = await desktopNoty(title, text.body || text)
      }
    } else if (type === 'chat.new_message') {
      state.unreadMessages = [...state.unreadMessages, data]
      playSound(newMessageSound)
      const title = document.title
      const newTitle = title.includes('🔴') ? title : '🔴 ' + title
      document.title = newTitle
    } else if (type === 'chat.unread_messages') {
      state.unreadMessages = data
      if (!data.length) document.title = document.title.replace('🔴 ', '')
    } else if (type === 'user.online') {
      state.usersOnline = data
    } else if (type === 'tickets') {
      this._vm.$toasted.info(`${data.title} - ${data.body}`, {
        // duration: 3000,
        action: {
          text: 'OK',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          },
        },
      })
    } else if (type === 'dialog') {
      this._vm.$swal(data)
    }
  },
  SOCKET_RECONNECT(state, count) {
    console.debug('websocket reconnect ' + count)
  },
  SOCKET_RECONNECT_ERROR(state) {
    state.socket.reconnectError = true
  },
  setPredictive(state, { loading, card }) {
    state.predictiveCard = card
    state.predictiveLoading = loading
  },
  deleteNoty(state, noty_id) {
    state.notys = state.notys.filter(n => n.noty_id !== noty_id)
  },
}
const actions = {
  deleteNoty({ commit, state }, noty_id) {
    this._vm.$socket.sendObj({ type: 'delete_noty', noty_id })
    commit('deleteNoty', noty_id)
    if (noty_id === state.notyDialogId) commit('setNotyDialog', null)
  },
  resetPredictive({ commit }) {
    commit('setPredictive', { loading: false, card: null })
  },
  getPredictive({ commit }) {
    commit('setPredictive', { card: null, loading: true })
    Vue.prototype.$socket.sendObj({ type: 'get_predictive' })
  },
  ommit({ commit }) {
    commit('setPredictive', { card: null, loading: true })
    Vue.prototype.$socket.sendObj({ type: 'ommit' })
  },
}

const getters = {
  isConnected(state) {
    return state.socket.isConnected
  },
  progress(state) {
    return state.progress
  },
  progressHint(state) {
    return state.progressHint
  },
  notyDialog(state) {
    return state.notys.find(n => n.noty_id === state.notyDialogId)
  },
  alerts(state) {
    const contracts = state.notys
      .filter(n => n.title === 'Nueva observación' && !n.is_noty_read && !n.solar)
      .map(({ contract_id }) => contract_id)
      .filter(onlyUnique)

    const solar_files = state.notys.filter(n => n.solar && !n.is_noty_read).filter(onlyUnique)
    const solar = state.notys.filter(n => n.solar && !n.is_noty_read).filter(onlyUnique)
    const tickets = state.notys.filter(n => !!n.ticket_id)
    const newCards = state.notys.filter(n => !!n.new_card)
    const agenda = state.notys.filter(n => n.agenda)

    return {
      agenda,
      tickets,
      contracts,
      solar_files,
      solar,
      fichas_captador: state.notys
        .filter(n => String(n.title).toLowerCase().includes('solar') && !n.is_noty_read)
        .filter(onlyUnique).length,
      newCards,
    }
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
