import * as LogRocket from 'logrocket'
import createPlugin from 'logrocket-vuex'
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import auth from './modules/auth'
import comparador from './modules/comparador'
import goodTable from './modules/goodTable'
import other from './modules/other'
import users from './modules/users'
import ws from './modules/ws'

Vue.use(Vuex)

const notSaveableMutations = [
  'setComment',
  'setManager',
  'setStatus',
  'setPredictive',
  'SOCKET_ONMESSAGE',
  'setCodigos',
  'updateToken',
  'storeIceServers',
  'setCrmOffers',
  'setCrmOffersLoading',
  'setCompanies',
]
const vuexLocal = new VuexPersistence({
  strict: process.env.NODE_ENV !== 'production',
  storage: window.localStorage,
  modules: ['auth', 'comparador', 'other'],
  filter: mutation => {
    return !notSaveableMutations.includes(mutation.type)
  },
})

const plugins = [vuexLocal.plugin]

if (!import.meta.env.DEV) plugins.push(createPlugin(LogRocket))

export default new Vuex.Store({
  modules: {
    ws,
    users,
    auth,
    other,
    goodTable,
    comparador,
  },
  strict: import.meta.env.DEV,
  plugins,
})
