import App from '@/App.vue'
import api from '@/api/index.js'
import { getWebsocketUrl } from '@/assets/constants'
import vuetify from '@/plugins/vuetify.js'
import router from '@/router/index.js'
import store from '@/store/index.js'
import * as Sentry from '@sentry/vue'
import Particles from '@tsparticles/vue2'
import { es } from 'date-fns/locale'
import 'sweetalert2/dist/sweetalert2.min.css'
import VCalendar from 'v-calendar'
import VueMask from 'v-mask'
import VueViewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import Vue from 'vue'
import VueDateFns from 'vue-date-fns'
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import VueNativeSock from 'vue-native-websocket'
import VueSweetalert2 from 'vue-sweetalert2'
import Toasted from 'vue-toasted'
import validationErrorHandler from '@/plugins/validationErrorHandler'
import Swal from 'sweetalert2/dist/sweetalert2.js'

const { PROD } = import.meta.env

const token = store.state.auth ? `${store.state.auth.jwt}` : null
if (token) {
  api.defaults.headers.Authorization = `Bearer ${token}`
  store.dispatch('updateProfile')
}
api.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error && error.response && error.response.status !== 400) {
      if (error.response.status === 401 && router.currentRoute.name !== 'login') {
        Vue.toasted.error('401 - No autorizado', { duration: 3000 })
        return
      } else if (error.response.status === 403) {
        Vue.toasted.error('403 - Permiso denegado', { duration: 3000 })
      } else if (error.response.status === 429) {
        Vue.toasted.error('Se ha superado el número permitido de solicitudes', { duration: 5000 })
      } else if (error.response.status === 404) {
        Vue.toasted.error('404 - No encontrado', { duration: 3000 })
      } else {
        Swal.fire({
          title: 'Error',
          icon: 'error',
          text: 'Error interno',
          toast: true,
          grow: 'row',
          width: '30em',
          timer: 5000,
          timerProgressBar: true,
          position: 'top-end',
        })
      }
    }
    return Promise.reject(error)
  },
)
Vue.prototype.api = api

Vue.use(VueSweetalert2)
Vue.use(VueViewer)
Vue.use(VueDateFns, { weekStartsOn: 1, locale: { es } })
Vue.use(VCalendar, { componentPrefix: 'vc' })
Vue.use(Toasted)
Vue.use(VueMask)
Vue.use(Particles, {
  init: async () => {},
})
Vue.use(validationErrorHandler)
Vue.component('VueDraggableResizable', VueDraggableResizable)

Vue.config.productionTip = false

Vue.use(VueNativeSock, token ? getWebsocketUrl(token) : '', {
  connectManually: true,
  store,
  format: 'json',
  reconnection: true,
  reconnectionAttempts: 5,
  reconnectionDelay: 3000,
})

const vm = new Vue({
  store,
  router,
  vuetify,
  watch: {
    '$store.state.auth.jwt'(v) {
      if (!v) {
        if (this.$socket) this.$disconnect()
        return
      }
      const newUrl = getWebsocketUrl(v)
      if (!this.$socket) {
        this.$connect(newUrl)
      } else if (newUrl.trim() !== this.$socket.url.trim()) {
        this.$disconnect()
        this.$connect(newUrl)
      }
    },
  },
  render: h => h(App),
}).$mount('#app')

window.vm = vm

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}
